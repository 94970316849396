.d-title-bold-28 {
  font-size: 28px;
  line-height: 38px;
  font-weight: 700;
}
.d-bold-24 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
}
.d-semibold-24 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
}
.d-bold-18 {
  font-size: 18px;
  line-height: 27px;
  font-weight: 700;
}
.d-semibold-18 {
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
}
.d-semibold-17 {
  font-size: 17px;
  line-height: 23px;
  font-weight: 600;
}
.d-bold-16 {
  font-size: 16px;
  line-height: 27px;
  font-weight: 700;
}
.d-semibold-16 {
  font-size: 16px;
  line-height: 27px;
  font-weight: 600;
}
.d-body-bold-14 {
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;
}
.d-semibold-14 {
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
}
.d-regular-14 {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
.d-semibold-12 {
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
}
.d-regular-12 {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}
.tm-title-bold-24 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
}
.tm-bold-20 {
  font-size: 20px;
  line-height: 27px;
  font-weight: 700;
}
.tm-bold-17 {
  font-size: 17px;
  line-height: 23px;
  font-weight: 700;
}
.tm-semibold-17 {
  font-size: 17px;
  line-height: 23px;
  font-weight: 600;
}
.tm-body-bold-15 {
  font-size: 15px;
  line-height: 22px;
  font-weight: 700;
}
.tm-semibold-15 {
  font-size: 15px;
  line-height: 22px;
  font-weight: 600;
}
.tm-regular-15 {
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
}
.tm-body-bold-13 {
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
}
.tm-semibold-13 {
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
}
.tm-regular-13 {
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
}
.tm-semibold-11 {
  font-size: 11px;
  line-height: 16px;
  font-weight: 600;
}
.tm-regular-11 {
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
}
.tm-bold-10 {
  font-size: 10px;
  line-height: 16px;
  font-weight: 700;
}
.tm-semibold-10 {
  font-size: 10px;
  line-height: 16px;
  font-weight: 600;
}
.regular {
  font-weight: 400;
}
.semi-bold {
  font-weight: 600;
}
.bold {
  font-weight: 700;
}
html {
  position: relative;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
}
body {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 300px;
  margin: 0;
  background-color: #EEF5FB;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
a {
  color: #0E72E3;
  text-decoration: none;
  transition: color 0.2s ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.desktop a:hover {
  color: #57A4FF;
}
a:active {
  color: #2589FF !important;
}
::-moz-selection {
  background: #3391FF;
  color: #FFFFFF;
}
::selection {
  background: #3391FF;
  color: #FFFFFF;
}
:focus {
  outline: none;
}
* {
  box-sizing: border-box;
}
*::before,
*::after {
  box-sizing: border-box;
}
svg {
  display: block;
}
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: inset 0 0 0 50px #fafafa !important;
  -webkit-text-fill-color: black !important;
}
.semi-bold {
  font-weight: 600;
}
i.rotate-90 {
  transform: rotate(90deg);
}
i.rotate-180 {
  transform: rotate(180deg);
}
i.rotate-270 {
  transform: rotate(270deg);
}
@font-face {
  font-family: 'tz-icons';
  src: url('fonts/tz-icons/fonts/tz-icons.eot?jrsfm1');
  src: url('fonts/tz-icons/fonts/tz-icons.eot?jrsfm1#iefix') format('embedded-opentype'), url('fonts/tz-icons/fonts/tz-icons.ttf?jrsfm1') format('truetype'), url('fonts/tz-icons/fonts/tz-icons.woff?jrsfm1') format('woff'), url('fonts/tz-icons/fonts/tz-icons.svg?jrsfm1#tz-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="tz-"],
[class*=" tz-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'tz-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tz-schedules-28:before {
  content: "\ea0a";
}
.tz-schedule-plan-28:before {
  content: "\ea09";
}
.tz-structure-types:before {
  content: "\ea07";
}
.tz-plan-20:before {
  content: "\ea08";
}
.tz-search:before {
  content: "\ea06";
}
.tz-settings:before {
  content: "\ea05";
}
.tz-choose-20:before {
  content: "\ea04";
}
.tz-user-owner-20:before {
  content: "\ea03";
}
.tz-export-template-20:before {
  content: "\ea01";
}
.tz-added-20:before {
  content: "\ea02";
}
.tz-toast-warning-32:before {
  content: "\e9fc";
}
.tz-toast-success-32:before {
  content: "\ea00";
}
.tz-toast-info-32:before {
  content: "\e9fe";
}
.tz-toast-error-32:before {
  content: "\e9ff";
}
.tz-presale-control-process-16:before {
  content: "\e9fb";
}
.tz-choice-circle-24:before {
  content: "\e9fa";
}
.tz-operational-control-process-16:before {
  content: "\e9f5";
}
.tz-warranty-service-process-16:before {
  content: "\e9f6";
}
.tz-units-handover-process-16:before {
  content: "\e9f7";
}
.tz-internal-acceptance-process-16:before {
  content: "\e9f8";
}
.tz-acceptance-control-process-16:before {
  content: "\e9f9";
}
.tz-repeat-16:before {
  content: "\e9f4";
}
.tz-fields-28:before {
  content: "\e9f3";
}
.tz-logo-latin:before {
  content: "\e9f1";
}
.tz-logo-cyrillic:before {
  content: "\e9f2";
}
.tz-empty-table:before {
  content: "\e9f0";
}
.tz-attach-20:before {
  content: "\e9ea";
}
.tz-links-list:before {
  content: "\e9dd";
}
.tz-custom-rhombus-20:before {
  content: "\e9de";
}
.tz-custom-socket-20:before {
  content: "\e9df";
}
.tz-custom-lamp-20:before {
  content: "\e9e0";
}
.tz-custom-shovel-20:before {
  content: "\e9e1";
}
.tz-custom-exclamation-point-20:before {
  content: "\e9e2";
}
.tz-custom-rectangles-20:before {
  content: "\e9e3";
}
.tz-custom-block-20:before {
  content: "\e9e4";
}
.tz-custom-snowflake-20:before {
  content: "\e9e5";
}
.tz-custom-shield-20:before {
  content: "\e9e6";
}
.tz-custom-triangle-20:before {
  content: "\e9e7";
}
.tz-custom-hexagon-20:before {
  content: "\e9e8";
}
.tz-custom-polygon-20:before {
  content: "\e9e9";
}
.tz-Icon1:before {
  content: "\e9dc";
  color: #fff;
}
.tz-inspection-add-24:before {
  content: "\e9d9";
}
.tz-problem-add-24:before {
  content: "\e9da";
}
.tz-task-add-24:before {
  content: "\e9db";
}
.tz-check-list-categories-20:before {
  content: "\e9d8";
}
.tz-space-16:before {
  content: "\e9d7";
}
.tz-tile-24:before {
  content: "\e9d6";
}
.tz-attach-16:before {
  content: "\e9d5";
}
.tz-document-16:before {
  content: "\e9d4";
}
.tz-problem-24:before {
  content: "\e9d2";
}
.tz-link-20:before {
  content: "\e9d3";
}
.tz-bank-account-28:before {
  content: "\e9d1";
}
.tz-classifier-20:before {
  content: "\e9ce";
}
.tz-address-20:before {
  content: "\e9d0";
}
.tz-phone-20:before {
  content: "\e9cf";
}
.tz-legal-entity-28:before {
  content: "\e9c9";
}
.tz-legal-entity-20:before {
  content: "\e9c1";
}
.tz-modified-20:before {
  content: "\e9c2";
}
.tz-number-20:before {
  content: "\e9c4";
}
.tz-price-20:before {
  content: "\e9c6";
}
.tz-stage-20:before {
  content: "\e9c7";
}
.tz-bank-account-20:before {
  content: "\e9c8";
}
.tz-contract-28:before {
  content: "\e9ca";
}
.tz-contractor-20:before {
  content: "\e9cb";
}
.tz-created-20:before {
  content: "\e9cc";
}
.tz-file-type-pdf:before {
  content: "\e9be";
}
.tz-file-type-doc:before {
  content: "\e9cd";
}
.tz-file-type-xls:before {
  content: "\e9c0";
}
.tz-inspector-20:before {
  content: "\e9bd";
}
.tz-task-16:before {
  content: "\e9ba";
}
.tz-comment-20:before {
  content: "\e9b9";
}
.tz-problem-20:before {
  content: "\e9b8";
}
.tz-percent-20:before {
  content: "\e9b6";
}
.tz-unit-20:before {
  content: "\e9b7";
}
.tz-task-type-20:before {
  content: "\e9b5";
}
.tz-star-priority-20:before {
  content: "\e9b2";
}
.tz-outline-object-20:before {
  content: "\e9b1";
}
.tz-structure:before {
  content: "\e9af";
}
.tz-inspection-16:before {
  content: "\e9ab";
}
.tz-company-20:before {
  content: "\e9a9";
}
.tz-check-24:before {
  content: "\e9a7";
}
.tz-owner-acceptance-24:before {
  content: "\e9a3";
}
.tz-task-28:before {
  content: "\e92e";
}
.tz-check-28:before {
  content: "\e918";
}
.tz-owner-acceptance-28:before {
  content: "\e92d";
}
.tz-drag-and-drop-24:before {
  content: "\e9a1";
}
.tz-task-24:before {
  content: "\e9a2";
}
.tz-warning-20:before {
  content: "\e99f";
}
.tz-category-20:before {
  content: "\e994";
}
.tz-priority-highest-16:before {
  content: "\e99b";
}
.tz-priority-high-16:before {
  content: "\e995";
}
.tz-priority-medium-16:before {
  content: "\e996";
}
.tz-notifications-default-entity:before {
  content: "\e948";
}
.tz-notifications-default-type:before {
  content: "\e993";
}
.tz-notifications-attachment:before {
  content: "\e992";
}
.tz-notifications-message:before {
  content: "\e991";
}
.tz-notifications-inspection:before {
  content: "\e990";
}
.tz-notifications-status:before {
  content: "\e98d";
}
.tz-notifications-add:before {
  content: "\e98a";
}
.tz-notifications-edit:before {
  content: "\e988";
}
.tz-notifications-delete:before {
  content: "\e986";
}
.tz-prescription-20:before {
  content: "\e982";
}
.tz-eraser-24:before {
  content: "\e983";
}
.tz-brush-24:before {
  content: "\e984";
}
.tz-brush-20:before {
  content: "\e985";
}
.tz-space-type-20:before {
  content: "\e980";
}
.tz-attachment:before {
  content: "\e97e";
}
.tz-chat:before {
  content: "\e987";
}
.tz-email:before {
  content: "\e989";
}
.tz-reason-external-link:before {
  content: "\e98b";
}
.tz-find:before {
  content: "\e98e";
}
.tz-fix-date:before {
  content: "\e98f";
}
.tz-toast-info:before {
  content: "\e9a4";
}
.tz-toast-success:before {
  content: "\e9a5";
}
.tz-toast-warning:before {
  content: "\e9a6";
}
.tz-problem-hexagon:before {
  content: "\e971";
}
.tz-few-sample:before {
  content: "\e910";
}
.tz-not-accepted:before {
  content: "\e96f";
}
.tz-repeat-sample:before {
  content: "\e970";
}
.tz-check-mark:before {
  content: "\e90f";
}
.tz-percent:before {
  content: "\e906";
}
.tz-logo:before {
  content: "\e904";
}
.tz-cloud:before {
  content: "\e929";
}
.tz-cloud-caching-active:before {
  content: "\e92a";
}
.tz-cloud-caching-done:before {
  content: "\e92b";
}
.tz-cloud-caching-error:before {
  content: "\e92c";
}
.tz-cloud-sync:before {
  content: "\e932";
}
.tz-arrow-16:before {
  content: "\e97a";
}
.tz-arrow-20:before {
  content: "\e974";
}
.tz-long-arrow-24:before {
  content: "\e928";
}
.tz-arrow-24:before {
  content: "\e954";
}
.tz-long-arrow:before {
  content: "\e902";
}
.tz-simple-arrow-16:before {
  content: "\e927";
}
.tz-simple-arrow-20:before {
  content: "\e95f";
}
.tz-simple-arrow-24:before {
  content: "\e920";
}
.tz-simple-arrow-28:before {
  content: "\e96b";
}
.tz-expand-arrow-heavy:before {
  content: "\e913";
}
.tz-corner-arrow:before {
  content: "\e921";
}
.tz-reload-20:before {
  content: "\e975";
}
.tz-reload-24:before {
  content: "\e945";
}
.tz-status-20:before {
  content: "\e946";
}
.tz-calendar-20:before {
  content: "\e947";
}
.tz-calendar-24:before {
  content: "\e916";
}
.tz-calendar-done-20:before {
  content: "\e9b4";
}
.tz-location-20:before {
  content: "\e949";
}
.tz-indicator-20:before {
  content: "\e964";
}
.tz-area-20:before {
  content: "\e965";
}
.tz-space-owner-20:before {
  content: "\e966";
}
.tz-description-20:before {
  content: "\e94a";
}
.tz-external-link-16:before {
  content: "\e937";
}
.tz-external-link-20:before {
  content: "\e94b";
}
.tz-photo-20:before {
  content: "\e950";
}
.tz-document-20:before {
  content: "\e94c";
}
.tz-edit-16:before {
  content: "\e967";
}
.tz-edit-20:before {
  content: "\e94d";
}
.tz-clock:before {
  content: "\e935";
}
.tz-alarm-clock:before {
  content: "\e936";
}
.tz-plan:before {
  content: "\e905";
}
.tz-map:before {
  content: "\e907";
}
.tz-menu:before {
  content: "\e90a";
}
.tz-more-16:before {
  content: "\e977";
}
.tz-more-24:before {
  content: "\e931";
}
.tz-edit:before {
  content: "\e908";
}
.tz-delete:before {
  content: "\e909";
}
.tz-copy:before {
  content: "\e90d";
}
.tz-user-20:before {
  content: "\e94e";
}
.tz-user-24:before {
  content: "\e933";
}
.tz-users-24:before {
  content: "\e972";
}
.tz-add-user-24:before {
  content: "\e978";
}
.tz-camera-24:before {
  content: "\e94f";
}
.tz-exit:before {
  content: "\e934";
}
.tz-comment:before {
  content: "\e93f";
}
.tz-comment-88:before {
  content: "\e951";
}
.tz-plus-16:before {
  content: "\e969";
}
.tz-plus-20:before {
  content: "\e952";
}
.tz-plus-24:before {
  content: "\e940";
}
.tz-minus:before {
  content: "\e941";
}
.tz-attach-24:before {
  content: "\e942";
}
.tz-send:before {
  content: "\e943";
}
.tz-mark-16:before {
  content: "\e90b";
}
.tz-mark-2-16:before {
  content: "\e979";
}
.tz-mark-24:before {
  content: "\e90c";
}
.tz-close-16:before {
  content: "\e924";
}
.tz-close-20:before {
  content: "\e903";
}
.tz-close-24:before {
  content: "\e925";
}
.tz-table-sort-20:before {
  content: "\e911";
}
.tz-filters-24:before {
  content: "\e914";
}
.tz-export-20:before {
  content: "\e953";
}
.tz-export-24:before {
  content: "\e915";
}
.tz-bar-chart:before {
  content: "\e922";
}
.tz-pie-chart:before {
  content: "\e923";
}
.tz-line-chart:before {
  content: "\e926";
}
.tz-company-28:before {
  content: "\e957";
}
.tz-object-28:before {
  content: "\e958";
}
.tz-internal-acceptance-28:before {
  content: "\e962";
}
.tz-internal-acceptance-24:before {
  content: "\e939";
}
.tz-problem-16:before {
  content: "\e90e";
}
.tz-problem-28:before {
  content: "\e968";
}
.tz-problem:before {
  content: "\e919";
}
.tz-problem-waiting:before {
  content: "\e917";
}
.tz-problem-handling:before {
  content: "\e91b";
}
.tz-problem-verification:before {
  content: "\e91c";
}
.tz-problem-fixed:before {
  content: "\e91d";
}
.tz-problem-expired:before {
  content: "\e91e";
}
.tz-problem-created:before {
  content: "\e91f";
}
.tz-comment-40:before {
  content: "\e955";
}
.tz-inspection:before {
  content: "\e91a";
}
.tz-inspection-28:before {
  content: "\e96a";
}
.tz-space:before {
  content: "\e938";
}
.tz-space-24:before {
  content: "\e93a";
}
.tz-space-40:before {
  content: "\e956";
}
.tz-space-status-28:before {
  content: "\e95a";
}
.tz-warranty-claim-24:before {
  content: "\e93b";
}
.tz-warranty-claim-28:before {
  content: "\e963";
}
.tz-statistics-28:before {
  content: "\e9aa";
}
.tz-user-28:before {
  content: "\e95b";
}
.tz-role-28:before {
  content: "\e95d";
}
.tz-export-template-28:before {
  content: "\e95e";
}
.tz-reg-standard-28:before {
  content: "\e960";
}
.tz-log-28:before {
  content: "\e961";
}
.tz-help:before {
  content: "\e930";
}
.tz-docx:before {
  content: "\e93c";
}
.tz-xlsx:before {
  content: "\e93d";
}
.tz-pdf:before {
  content: "\e93e";
}
.tz-file:before {
  content: "\e912";
}
.tz-circle-warning-32:before {
  content: "\e944";
}
.tz-search-20:before {
  content: "\e96c";
}
.tz-info-20:before {
  content: "\e96d";
}
.tz-info-24:before {
  content: "\e96e";
}
.tz-settings-24:before {
  content: "\e973";
}
.tz-name-20:before {
  content: "\e976";
}
.tz-list-24:before {
  content: "\e901";
}
.tz-schema-24:before {
  content: "\e92f";
}
.tz-delegate-20:before {
  content: "\e97b";
}
.tz-delegate-24:before {
  content: "\e9a8";
}
.tz-critical-20:before {
  content: "\e9ae";
}
.tz-history-24:before {
  content: "\e97c";
}
.tz-mark-20:before {
  content: "\e997";
}
.tz-offline-mode-20:before {
  content: "\e99a";
}
.tz-data-transfer-error-20:before {
  content: "\e998";
}
.tz-data-transfer-20:before {
  content: "\e999";
}
.tz-password-visible-20:before {
  content: "\e99c";
}
.tz-password-hidden-20:before {
  content: "\e99d";
}
.tz-mail-20:before {
  content: "\e99e";
}
.tz-tag-20:before {
  content: "\e9a0";
}
.tz-object-24:before {
  content: "\e9ac";
}
.tz-comment-on-plan-16:before {
  content: "\e9b3";
}
.tz-work-acceptance-28:before {
  content: "\e9ad";
}
.tz-work-acceptance-16:before {
  content: "\e9bc";
}
.tz-export-template-manage-28:before {
  content: "\e900";
}
.tz-tag-manage-28:before {
  content: "\e959";
}
.tz-category-manage-28:before {
  content: "\e95c";
}
.tz-space-type-manage-28:before {
  content: "\e97d";
}
.tz-group-manage-28:before {
  content: "\e97f";
}
.tz-check-list-16:before {
  content: "\e9bb";
}
.tz-check-list-manage-28:before {
  content: "\e981";
}
.tz-space-status-manage-28:before {
  content: "\e98c";
}
.tz-eye-20:before {
  content: "\e9b0";
}
.tz-document-type-20:before {
  content: "\e9bf";
}
.tz-document-format-20:before {
  content: "\e9c3";
}
.tz-customer-20:before {
  content: "\e9c5";
}
.tz-heat-20:before {
  content: "\e9eb";
}
.tz-gas-20:before {
  content: "\e9ec";
}
.tz-electricity-20:before {
  content: "\e9ed";
}
.tz-water-cold-20:before {
  content: "\e9ee";
}
.tz-water-hot-20:before {
  content: "\e9ef";
}
.tz-presale-check-28:before {
  content: "\e9fd";
}
.tz-search {
  font-size: 24px;
}
.tz-settings {
  font-size: 24px;
}
.tz-choice-circle-24 {
  font-size: 24px;
}
.tz-toast-info-32 {
  font-size: 32px;
}
.tz-toast-warning-32 {
  font-size: 32px;
}
.tz-toast-error-32 {
  font-size: 32px;
}
.tz-toast-success-32 {
  font-size: 32px;
}
.tz-logo-latin,
.tz-logo-cyrillic {
  font-size: 36px;
}
.tz-logo {
  font-size: 40px;
}
.tz-cloud {
  font-size: 32px;
}
.tz-cloud-caching-active {
  font-size: 32px;
}
.tz-cloud-caching-done {
  font-size: 32px;
}
.tz-cloud-caching-error {
  font-size: 32px;
}
.tz-cloud-sync {
  font-size: 32px;
}
.tz-long-arrow-24 {
  font-size: 24px;
}
.tz-arrow-16 {
  font-size: 16px;
}
.tz-arrow-20 {
  font-size: 20px;
}
.tz-arrow-24 {
  font-size: 24px;
}
.tz-long-arrow {
  font-size: 0.71em;
}
.tz-simple-arrow-16 {
  font-size: 16px;
}
.tz-simple-arrow-20 {
  font-size: 20px;
}
.tz-simple-arrow-24 {
  font-size: 24px;
}
.tz-simple-arrow-28 {
  font-size: 28px;
}
.tz-expand-arrow-heavy {
  font-size: 8px;
}
.tz-corner-arrow {
  font-size: 24px;
}
.tz-reload-20 {
  font-size: 20px;
}
.tz-reload-24 {
  font-size: 24px;
}
.tz-blocks {
  font-size: 0.85em;
}
.tz-list {
  font-size: 0.92em;
}
.tz-question {
  font-size: 1.06em;
}
.tz-status-20 {
  font-size: 20px;
}
.tz-calendar-20 {
  font-size: 20px;
}
.tz-calendar-24 {
  font-size: 24px;
}
.tz-calendar-done-20 {
  font-size: 20px;
}
.tz-category-20 {
  font-size: 20px;
}
.tz-category-manage-28 {
  font-size: 28px;
}
.tz-location-20 {
  font-size: 20px;
}
.tz-indicator-20 {
  font-size: 20px;
}
.tz-area-20 {
  font-size: 20px;
}
.tz-space-owner-20 {
  font-size: 20px;
}
.tz-description-20 {
  font-size: 20px;
}
.tz-external-link-16 {
  font-size: 16px;
}
.tz-external-link-20 {
  font-size: 20px;
}
.tz-photo-20 {
  font-size: 20px;
}
.tz-document-20 {
  font-size: 20px;
}
.tz-edit-16 {
  font-size: 16px;
}
.tz-edit-20 {
  font-size: 20px;
}
.tz-clock {
  font-size: 20px;
}
.tz-alarm-clock {
  font-size: 20px;
}
.tz-plan {
  font-size: 0.83em;
}
.tz-map {
  font-size: 1.17em;
}
.tz-menu {
  font-size: 24px;
}
.tz-more-16 {
  font-size: 16px;
}
.tz-more-24 {
  font-size: 24px;
}
.tz-edit {
  font-size: 24px;
}
.tz-delete {
  font-size: 24px;
  color: #FF576F;
}
.tz-delete-20 {
  font-size: 20px;
  color: #FF576F;
}
.tz-copy {
  font-size: 24px;
}
.tz-copy-20 {
  font-size: 20px;
}
.tz-user-20 {
  font-size: 20px;
}
.tz-user-24 {
  font-size: 24px;
}
.tz-users-24 {
  font-size: 24px;
}
.tz-add-user-24 {
  font-size: 24px;
}
.tz-camera-24 {
  font-size: 24px;
}
.tz-exit {
  font-size: 24px;
}
.tz-comment {
  font-size: 24px;
}
.tz-comment-88 {
  font-size: 88px;
}
.tz-plus-20 {
  font-size: 20px;
}
.tz-plus-24 {
  font-size: 24px;
}
.tz-minus {
  font-size: 24px;
}
.tz-attach-20 {
  font-size: 20px;
}
.tz-attach-24 {
  font-size: 24px;
}
.tz-send {
  font-size: 24px;
}
.tz-mark-16 {
  font-size: 16px;
}
.tz-mark-2-16 {
  font-size: 16px;
}
.tz-mark-24 {
  font-size: 24px;
}
.tz-close-16 {
  font-size: 16px;
}
.tz-close-20 {
  font-size: 20px;
}
.tz-close-24 {
  font-size: 24px;
}
.tz-table-sort-20 {
  font-size: 20px;
}
.tz-filters-24 {
  font-size: 24px;
}
.tz-export-20 {
  font-size: 20px;
}
.tz-export-24 {
  font-size: 24px;
}
.tz-bar-chart {
  font-size: 24px;
}
.tz-pie-chart {
  font-size: 24px;
}
.tz-line-chart {
  font-size: 24px;
}
.tz-company-20 {
  font-size: 20px;
}
.tz-company-28 {
  font-size: 28px;
}
.tz-object-24 {
  font-size: 24px;
}
.tz-object-28 {
  font-size: 28px;
}
.tz-internal-acceptance-24 {
  font-size: 24px;
}
.tz-internal-acceptance-28 {
  font-size: 28px;
}
.tz-check-24 {
  font-size: 24px;
}
.tz-check-28 {
  font-size: 28px;
}
.tz-check-40 {
  font-size: 40px;
}
.tz-presale-check-28 {
  font-size: 28px;
}
.tz-problem {
  font-size: 28px;
}
.tz-problem-16 {
  font-size: 16px;
}
.tz-problem-24 {
  font-size: 24px;
}
.tz-problem-28 {
  font-size: 28px;
}
.tz-problem-40 {
  font-size: 40px;
}
.tz-problem-waiting {
  font-size: 28px;
}
.tz-problem-waiting-40 {
  font-size: 40px;
}
.tz-problem-handling {
  font-size: 28px;
}
.tz-problem-handling-40 {
  font-size: 40px;
}
.tz-problem-verification {
  font-size: 28px;
}
.tz-problem-verification-40 {
  font-size: 40px;
}
.tz-problem-fixed {
  font-size: 28px;
}
.tz-problem-fixed-40 {
  font-size: 40px;
}
.tz-problem-expired {
  font-size: 28px;
}
.tz-problem-expired-40 {
  font-size: 40px;
}
.tz-problem-created {
  font-size: 28px;
}
.tz-problem-created-40 {
  font-size: 40px;
}
.tz-comment-40 {
  font-size: 40px;
}
.tz-inspection {
  font-size: 28px;
}
.tz-inspection-24 {
  font-size: 24px;
}
.tz-inspection-28 {
  font-size: 28px;
}
.tz-inspection-40 {
  font-size: 40px;
}
.tz-space {
  font-size: 28px;
}
.tz-space-16 {
  font-size: 16px;
}
.tz-space-24 {
  font-size: 24px;
}
.tz-space-40 {
  font-size: 40px;
}
.tz-space-status-manage-28 {
  font-size: 28px;
}
.tz-owner-acceptance-24 {
  font-size: 24px;
}
.tz-owner-acceptance-28 {
  font-size: 28px;
}
.tz-warranty-claim-24 {
  font-size: 24px;
}
.tz-warranty-claim-28 {
  font-size: 28px;
}
.tz-warranty-claim-40 {
  font-size: 40px;
}
.tz-statistics-28 {
  font-size: 28px;
}
.tz-user-28 {
  font-size: 28px;
}
.tz-role-28 {
  font-size: 28px;
}
.tz-export-template-manage-28 {
  font-size: 28px;
}
.tz-reg-standard-28 {
  font-size: 28px;
}
.tz-log-28 {
  font-size: 28px;
}
.tz-help {
  font-size: 28px;
}
.tz-docx {
  font-size: 32px;
  color: #1652b5;
}
.tz-xlsx {
  font-size: 32px;
  color: #007732;
}
.tz-pdf {
  font-size: 32px;
  color: #ff402f;
}
.tz-file {
  font-size: 32px;
}
.tz-circle-warning-32 {
  font-size: 32px;
}
.tz-search-20 {
  font-size: 20px;
}
.tz-info-20 {
  font-size: 20px;
}
.tz-info-24 {
  font-size: 24px;
}
.tz-settings-24 {
  font-size: 24px;
}
.tz-name-20 {
  font-size: 20px;
}
.tz-list-24 {
  font-size: 24px;
}
.tz-schema-24 {
  font-size: 24px;
}
.tz-delegate-20 {
  font-size: 20px;
}
.tz-delegate-24 {
  font-size: 24px;
}
.tz-history-24 {
  font-size: 24px;
}
.tz-users-16 {
  font-size: 16px;
}
.tz-critical-20 {
  font-size: 20px;
}
.tz-history-24 {
  font-size: 24px;
}
.tz-toast-info {
  font-size: 24px;
}
.tz-toast-success {
  font-size: 24px;
}
.tz-toast-warning {
  font-size: 24px;
}
.tz-fix-date {
  font-size: 18px;
}
.tz-attachment {
  font-size: 18px;
}
.tz-reason-field {
  font-size: 20px;
}
.tz-reason-external-link {
  font-size: 18px;
}
.tz-prescription-20 {
  font-size: 20px;
}
.tz-group-manage-28 {
  font-size: 28px;
}
.tz-check-list-manage-28 {
  font-size: 28px;
}
.tz-check-list-16 {
  font-size: 16px;
}
.tz-check-list-categories-20 {
  font-size: 20px;
}
.tz-space-type-20 {
  font-size: 20px;
}
.tz-space-type-manage-28 {
  font-size: 28px;
}
.tz-brush-20 {
  font-size: 20px;
}
.tz-brush-24 {
  font-size: 24px;
}
.tz-eraser-24 {
  font-size: 24px;
}
.tz-priority-highest-16 {
  font-size: 16px;
}
.tz-priority-high-16 {
  font-size: 16px;
}
.tz-priority-medium-16 {
  font-size: 16px;
}
.tz-data-transfer-20 {
  font-size: 20px;
}
.tz-data-transfer-error-20 {
  font-size: 20px;
}
.tz-offline-mode-20 {
  font-size: 20px;
}
.tz-mark-20 {
  font-size: 20px;
}
.tz-password-visible-20 {
  font-size: 20px;
}
.tz-password-hidden-20 {
  font-size: 20px;
}
.tz-mail-20 {
  font-size: 20px;
}
.tz-task-24 {
  font-size: 24px;
}
.tz-task-16 {
  font-size: 16px;
}
.tz-task-28 {
  font-size: 28px;
}
.tz-drag-and-drop-24 {
  font-size: 24px;
}
.tz-tag-manage-28 {
  font-size: 28px;
}
.tz-tag-20 {
  font-size: 20px;
}
.tz-inspection-16 {
  font-size: 16px;
}
.tz-warning-20 {
  font-size: 20px;
}
.tz-comment-on-plan-16 {
  font-size: 16px;
}
.tz-work-acceptance-28 {
  font-size: 28px;
}
.tz-work-acceptance-16 {
  font-size: 16px;
}
.tz-comment-on-plan-16 {
  font-size: 16px;
}
.tz-eye-20 {
  font-size: 20px;
}
.tz-outline-object-20 {
  font-size: 20px;
}
.tz-star-priority-20 {
  font-size: 20px;
}
.tz-task-type-20 {
  font-size: 20px;
}
.tz-comment-20 {
  font-size: 20px;
}
.tz-problem-20 {
  font-size: 20px;
}
.tz-task-20 {
  font-size: 20px;
}
.tz-percent-20 {
  font-size: 20px;
}
.tz-unit-20 {
  font-size: 20px;
}
.tz-notifications-export-28 {
  font-size: 28px;
}
.tz-notifications-send-28 {
  font-size: 28px;
}
.tz-file-type-pdf {
  font-size: 24px;
}
.tz-file-type-doc {
  font-size: 24px;
}
.tz-file-type-xls {
  font-size: 24px;
}
.tz-bank-account-20 {
  font-size: 20px;
}
.tz-customer-20 {
  font-size: 20px;
}
.tz-contract-16 {
  font-size: 16px;
}
.tz-contract-28 {
  font-size: 28px;
}
.tz-contractor-20 {
  font-size: 20px;
}
.tz-created-20 {
  font-size: 20px;
}
.tz-modified-20 {
  font-size: 20px;
}
.tz-legal-entity-20 {
  font-size: 20px;
}
.tz-number-20 {
  font-size: 20px;
}
.tz-price-20 {
  font-size: 20px;
}
.tz-stage-20 {
  font-size: 20px;
}
.tz-document-format-20 {
  font-size: 20px;
}
.tz-legal-entity-28 {
  font-size: 28px;
}
.tz-phone-20 {
  font-size: 20px;
}
.tz-classifier-20 {
  font-size: 20px;
}
.tz-address-20 {
  font-size: 20px;
}
.tz-bank-account-28 {
  font-size: 28px;
}
.tz-link-20 {
  font-size: 20px;
}
.tz-document-16 {
  font-size: 16px;
}
.tz-attach-16 {
  font-size: 16px;
}
.tz-tile-24 {
  font-size: 24px;
}
.tz-inspection-add-24 {
  font-size: 24px;
}
.tz-problem-add-24 {
  font-size: 24px;
}
.tz-task-add-24 {
  font-size: 24px;
}
.tz-inspector-20 {
  font-size: 20px;
}
.tz-custom-rhombus-20 {
  font-size: 20px;
}
.tz-custom-socket-20 {
  font-size: 20px;
}
.tz-custom-lamp-20 {
  font-size: 20px;
}
.tz-custom-shovel-20 {
  font-size: 20px;
}
.tz-custom-exclamation-point-20 {
  font-size: 20px;
}
.tz-custom-rectangles-20 {
  font-size: 20px;
}
.tz-custom-block-20 {
  font-size: 20px;
}
.tz-custom-snowflake-20 {
  font-size: 20px;
}
.tz-custom-snowflake-24 {
  font-size: 24px;
}
.tz-custom-shield-20 {
  font-size: 20px;
}
.tz-custom-triangle-20 {
  font-size: 20px;
}
.tz-custom-hexagon-20 {
  font-size: 20px;
}
.tz-custom-polygon-20 {
  font-size: 20px;
}
.tz-links-list-20 {
  font-size: 20px;
}
.tz-water-cold-20 {
  font-size: 20px;
}
.tz-water-hot-20 {
  font-size: 20px;
}
.tz-electricity-20 {
  font-size: 20px;
}
.tz-heat-20 {
  font-size: 20px;
}
.tz-gas-20 {
  font-size: 20px;
}
.tz-empty-table {
  font-size: 184px;
}
.tz-fields-28 {
  font-size: 28px;
}
.tz-air-conditioner-20 {
  font-size: 20px;
}
.tz-repeat-16 {
  font-size: 16px;
}
.tz-operational-control-process-16 {
  font-size: 16px;
}
.tz-warranty-service-process-16 {
  font-size: 16px;
}
.tz-units-handover-process-16 {
  font-size: 16px;
}
.tz-internal-acceptance-process-16 {
  font-size: 16px;
}
.tz-acceptance-control-process-16 {
  font-size: 16px;
}
.tz-choice-circle-24 {
  font-size: 24px;
}
.tz-export-template-20 {
  font-size: 20px;
}
.tz-added-20 {
  font-size: 20px;
}
.tz-document-type-20 {
  font-size: 20px;
}
.tz-user-owner-20 {
  font-size: 20px;
}
.tz-choose-20 {
  font-size: 20px;
}
.tz-plan-20 {
  font-size: 20px;
}
.tz-structure-types-20 {
  font-size: 20px;
}
.tz-schedule-plan-28 {
  font-size: 28px;
}
.tz-schedules-28 {
  font-size: 28px;
}
@keyframes placeholder-animation {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
