@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';

.text {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.remainingCount {
    color: @deepBlue;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600
}