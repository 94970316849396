@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.title {
	margin: 0;
	margin-bottom: 24px;
	color: @black4;
	.d-semibold-18();
}

.form {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 40px;
	row-gap: 26px;
}

.formItem {
	max-width: 354px;
}

.scrollBar {
	height: calc(100vh - 315px);
}

.plate {
	max-width: 876px;
}

.plateContent {
	padding-left: 64px;
	padding-right: 0px;
	padding-top: 40px;
}

.container {
	max-height: 100%;
	padding-right: 64px;
}
