@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.selectionRowWrapper {
    display: flex;
    align-items: center
}

.selectedEntityCounter {
    .d-regular-14();
    color: @black60;
    margin-right: 38px;
    display: flex;
    align-items: center;
}

.icon {
    margin-right: 36px;
}