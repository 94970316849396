@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.container {
	display: grid;
	grid-template-columns: auto 1fr;
	align-items: center;
	gap: 8px;
}

.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	height: 100%;
	padding-top: 2px;
	padding-bottom: 2px;
}

.chainOuterItem {
	width: 6px;
	height: 6px;
	background-color: @grey30;
	border-radius: 50%;
}

.chainInnerItem {
	width: 2px;
	height: 2px;
	background-color: @grey30;
	border-radius: 50%;
}

.itemsContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 8px;
}

.item {
	color: @black4;
	.d-regular-14();
}
