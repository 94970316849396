@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.header {
	color: @black85;
	.d-regular-14();
}

.wrap {
	justify-content: space-between;
}

.button {
	width: 330px;
	min-height: 48px;
	background-color: @white2 !important;
	border-radius: 8px;
	border: 1px @white2 solid;
}

.buttonOpen {
	border: 1px @blue40 solid;
}

.label {
	.d-semibold-14();
}

.menu {
	width: 296px;
}

.menuItem {
	position: relative;
	.d-semibold-14();
	color: @black4;
}

.menuItemIcon {
	position: absolute;
	right: 20px;
	color: @blue2;
}
