@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.root {
	background-color: inherit;
	box-shadow: none;
	border-radius: 0;
	margin-top: 35px;
}

.content {
	padding: 0px;
	display: flex;
	justify-content: space-between;
	margin-top: 35px;
}

.rootButton {
	display: flex;
	justify-content: flex-start;
	height: auto;
	padding: 0;
}

.rightIcon {
	color: @buttonAccent;
	font-size: 24px;
}

.label {
	.d-semibold-14();
	margin-left: 12px;
	order: 2;
	color: @deepBlue;
	text-align: center;
}
.dateRange {
	column-gap: 0;
}

.rangeDash {
	display: inline-block;
	margin: 0 12px;
	width: 14px;
	height: 10px;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 50%;
		width: 100%;
		height: 2px;
		background-color: @grey;
		transform: translateY(-50%);
	}
}

.slots {
	width: 330px;
}
