@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.container {
	display: flex;
	align-items: center;
	column-gap: 12px;
}

.datePicker {
	width: 158px;
}

.icon {
	color: @grey;
}
