@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/colors';

.cell {
	padding-left: 0;
}
.cellTitle {
	.d-semibold-14();
	color: @black4;
}

.cellSubtitle {
	.d-regular-14();
	color: @black75;
}
