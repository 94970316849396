@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.inputWrapper {
	display: flex;
	flex-wrap: wrap;
}

.intervalErrorMessage {
	margin-top: 6px;
	color: @red70;
	flex-basis: 100%;
	font-weight: 400;
	font-size: 12px;
	line-height: 15.84px;
	letter-spacing: 0%;
}

.rangeDash {
	font-weight: 600;
	font-size: 14px;
	line-height: 19.6px;
	letter-spacing: 0%;
}

.rangeDash {
	display: inline-block;
	margin: 0 12px;
	width: 14px;
	height: 10px;
	position: relative;
	top: 20px;

	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 50%;
		width: 100%;
		height: 2px;
		background-color: @grey;
		transform: translateY(-50%);
	}
}

.inputsHeader {
	color: @black85;
	.d-regular-14();
}
