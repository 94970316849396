@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.container {
	display: flex;
	align-items: center;
}

.datePicker {
	width: 144px;
}

.icon {
	color: @grey;
}
