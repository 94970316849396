@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.selectionRowWrapper {
	display: flex;
}
.selectedEntityCounter {
	.d-regular-14();
	color: @black60;
	margin-right: 16px;
	display: flex;
	align-items: center;
}
