.table {
	overflow-x: auto;
}

.deleteCell {
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.2s ease;
}

.deleteCellHover {
	&:hover .deleteCell {
		visibility: visible;
		opacity: 1;
	}
}
.hoveredCell {
	&:hover .deleteCell {
		visibility: visible;
		opacity: 1;
	}
}
