@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.buttonsWrap {
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
}

.labelWrap {
	margin-bottom: 12px;
	display: flex;
	justify-content: space-between;
}

.buttonDisable {
	background-color: @grey10;
	color: @black20;
	cursor: default;
}

.cancelAllBtn {
	align-self: center;
	padding: 0 16px;
}

.label {
	color: @black85;
	.d-regular-14();
}

.errorMessage {
	margin-top: 16px;
	color: @red70;
	flex-basis: 100%;
	.d-regular-12();
}
