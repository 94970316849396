.root {
	max-width: 576px;
}

.title {
	white-space: break-spaces;
}
.body {
	display: flex;
	min-width: 448px;
	column-gap: 15px;
}

.item {
	width: 100%;
	min-width: 216px;
}
