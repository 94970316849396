@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.wrap {
	position: relative;
	max-width: 146px;
}

.element {
	caret-color: @blue2;
	width: 146px;
}

.placeholder {
	.d-semibold-14();
	line-height: 14px;
	color: @black35;
	z-index: 1;
	display: flex;
	position: absolute;
	top: 16px;
	left: 16px;
}

.hours {
	display: inline-block;
	border-bottom: 1px solid @black1;
}

.minutes {
	display: inline-block;
	border-bottom: 1px solid @black1;
}
