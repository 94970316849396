@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

@base-unit: 4px;

.root {
	background-color: @buttonFilterActive;
	width: 748px;
	border-radius: @base-unit * 4;
	padding: @base-unit * 6;
	box-shadow: none;
	align-self: center;
	margin-bottom: 24px;
}
.wrap {
	display: flex;
	flex-direction: column;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 34px;
}

.headerText {
	.tm-regular-15();
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0%;
	margin: 0;
	padding: 0;
}

.topInputsWrap {
	display: flex;
	justify-content: space-between;
	margin-bottom: 26px;
}
