@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/colors';

.wrap {
	margin-top: 80px;
	margin-bottom: 40px;
}

.header {
	.d-semibold-18();
	margin-bottom: 24px;
}

.activeButton,
.disabledButton {
	.d-semibold-14();
	padding-left: 0;
	background-color: inherit;
}

.activeButton {
	color: @deepBlue;
}

.disabledButton {
	color: @grey30;
}

.limitErrorMessage {
	.d-regular-12();
	color: @red70;
	margin-bottom: 0;
}
