@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/shadows';

.work-acceptance-page {
	&__check-lists {
		padding: 16px 16px 0;
	}

	&__breadcrumbs {
		@media (min-width: @tablet-min) and (max-width: @large-tablet-max) {
			margin-bottom: 0;
		}

		@media (max-width: @tablet-max) {
			margin-bottom: 0;
		}
	}

	&__space-btn {
		padding-left: 0;
	}

	&__cards-container {
		display: grid;
		grid-template-columns: 1fr;
		grid-auto-rows: min-content;
		grid-gap: 32px 0;

		@media (max-width: @mobile-max) {
			padding: 0 15px;
			grid-gap: 16px 0;
		}
	}

	&__plate-card {
		@media (max-width: @tablet-min) {
			border-radius: 16px;
		}
	}

	&__info-plate {
		padding: 27px 24px;
	}

	&__info-content {
		margin-top: 32px;

		@media (max-width: @mobile-max) {
			margin-top: 16px;
		}
	}

	&__info-edit-icon {
		margin-right: 11px;
	}

	&__documents {
		&-file:not(:last-child) {
			margin-bottom: 16px;
		}

		&-adding-btn {
			margin-top: 24px;
			padding: 10px;
			background-color: @buttonLight;
			border-radius: 8px;

			.button-base& {
				border: 1px solid @iconButtonHover;
			}
		}
	}

	&__d-comments-plate {
		height: 100%;

		&-content {
			height: 100%;
			padding: 0;
		}
	}

	&__d-columns {
		.app-content_padding_288 & {
			@media (min-width: @large-tablet-min) and (max-width: @tablet-max) {
				display: grid;
				grid-column-gap: 24px;
				grid-template-areas:
					'attachments comments'
					'info comments';
				grid-template-columns: 368px 1fr;
			}
		}

		@media (min-width: @large-tablet-min) {
			display: flex;
			align-items: flex-start;
		}
	}

	&__d-column {
		&:not(:last-child) {
			margin-right: 24px;

			@media (min-width: @desktop-min) {
				margin-right: 32px;
			}
		}

		&_result-title {
			color: @black100;
			.d-semibold-16();
		}

		&_info {
			display: flex;
			flex: none;
			flex-direction: column;
			row-gap: 16px;

			.app-content_padding_72 & {
				@media (min-width: @large-tablet-min) and (max-width: @tablet-max) {
					width: 304px;
				}

				@media (min-width: @desktop-min) and (max-width: @large-tablet-max) {
					width: 368px;
				}
			}

			.app-content_padding_288 & {
				@media (min-width: @large-tablet-min) and (max-width: @tablet-max) {
					position: relative;
					top: 0;

					grid-area: attachments;

					width: 100%;
					margin: 0 0 24px;
				}

				@media (min-width: @desktop-min) and (max-width: @large-tablet-max) {
					width: 304px;
				}
			}

			@media (min-width: @large-desktop-min) {
				width: 368px;
			}
		}

		&_entities {
			top: -2px;
			flex: 1 1;
		}
		&_description {
			display: grid;
			grid-column-gap: 24px;
			align-items: flex-start;

			@media (min-width: @desktop-min) {
				grid-column-gap: 32px;
			}

			.app-content_padding_72 & {
				grid-template-columns: 304px 1fr 1fr;

				@media (min-width: @large-tablet-min) and (max-width: @desktop-min - 1px) {
					grid-template-columns: 304px 1fr 1fr;
				}

				@media (min-width: @desktop-min) {
					grid-template-columns: 0.55fr 0.45fr;
				}
			}

			.app-content_padding_288 & {
				@media (min-width: @large-tablet-min) and (max-width: @desktop-min - 1px) {
					display: grid;
					grid-template-columns: 368px 1fr;
					grid-template-areas:
						'attachments comments'
						'info comments';
					grid-column-gap: 24px;
				}

				@media (min-width: @desktop-min) and (max-width: @large-desktop-min - 1px) {
					grid-template-columns: 1fr 1fr;
				}

				@media (min-width: @large-desktop-min) {
					grid-template-columns: auto 41%;
				}
			}
		}

		@media (min-width: @large-tablet-min) {
			position: sticky;
			top: 22px;
		}

		&_comments {
			position: sticky;
			top: 22px;
		}
	}

	&__d-entities {
		&-info-cell {
			display: flex;
			align-items: flex-start;

			&_with-padding {
				padding-left: 58px;

				&::before {
					content: '';

					position: absolute;
					top: -1px;
					left: 0;

					display: block;

					width: 68px;
					height: 1px;

					background-color: @white;
				}
			}
		}

		&-info {
			overflow: hidden;
			flex: 1 1;
			margin-left: 26px;

			&-title-1:not(:last-child) {
				margin-bottom: 16px;
			}

			&-title-2:not(:last-child) {
				margin-bottom: 12px;
			}

			&-field:not(:last-child) {
				margin-bottom: 4px;
			}

			&-title-1,
			&-title-2,
			&-field {
				overflow-x: hidden;
				max-width: 100%;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		&-problem {
			display: flex;

			&-info {
				overflow: hidden;
				flex: 1 1;
			}
		}

		&-reply {
			margin: 24px 0 0 44px;
		}

		&-fix-date {
			margin-bottom: 16px;
			font-weight: 600;
		}

		&-resp-user {
			&:not(:last-child) {
				margin-bottom: 12px;
			}
		}

		&-link {
			padding-right: 20px;
			padding-left: 20px;
		}
	}

	&__d-entity-info {
		display: flex;
		align-items: flex-start;
		padding: 32px 32px 32px 80px;

		&-col {
			flex: 1 1 50%;

			&:not(:last-child) {
				padding-right: 32px;
			}

			&:first-child:last-child {
				flex: 1 1 100%;
			}
		}
	}

	&__d-controls {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 8px;

		&-right {
			flex-wrap: nowrap;
			gap: 16px;

			&-container {
				display: flex;
				gap: 16px;
				align-items: center;
				justify-content: space-between;
			}
		}
	}

	&__p-controls {
		display: flex;
		justify-content: right;
		margin-bottom: 24px;
	}

	&__m-entities {
		&:not(:last-child) {
			margin-bottom: 16px;
		}

		&-item {
			&:not(:last-child) {
				margin-bottom: 16px;
			}
		}

		&-info-cell {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			padding-right: 8px;

			&_with-padding {
				padding-left: 56px;

				&::before {
					content: '';

					position: absolute;
					top: -1px;
					left: 0;

					display: block;

					width: 56px;
					height: 1px;

					background-color: @white;
				}
			}
		}

		&-info {
			overflow: hidden;
			margin-right: 8px;

			&-title-1:not(:last-child) {
				margin-bottom: 16px;
			}

			&-title-2:not(:last-child) {
				margin-bottom: 12px;
			}

			&-field:not(:last-child) {
				margin-bottom: 4px;
			}
		}

		&-fix-date-item {
			.d-regular-14();
		}
	}

	&__tabs {
		@media (max-width: @tablet-max) {
			.shadow2();
		}

		@media (min-width: @desktop-min) {
			border-bottom: 1px solid @black10;
		}
	}

	&__tab-link {
		@media (max-width: @tablet-max) {
			&:first-child {
				margin-left: 18px;
			}

			&:last-child {
				margin-right: 18px;
			}
		}

		@media (min-width: @desktop-min) {
			&:first-child {
				margin-left: 24px;
			}

			&:last-child {
				margin-right: 24px;
			}
		}
	}

	&__tasks-title {
		color: @black100;
		.d-semibold-14();
	}

	&__add-task-icon {
		color: @blue2;
	}

	&__plate {
		&-header {
			display: flex;
			align-items: center;

			min-height: 56px;

			background-color: @white3;
			border-bottom: 1px solid @black10;

			&-tabs {
				flex: 1 1;
				align-self: end;

				&-links {
					padding-left: 24px;
				}
			}

			&-actions {
				margin-right: 12px;
				margin-left: auto;
			}
		}

		&-content {
			padding: 0 24px 24px;
		}
	}

	&__selected-structure {
		cursor: pointer;

		display: flex;
		flex-direction: row;
		align-items: center;

		width: fit-content;
		min-width: none;
		padding: 4px 8px;

		background-color: @blue10;
		border-radius: 6px;

		&_offset {
			margin-top: -16px;
			margin-bottom: 20px;
		}

		&_icon {
			display: flex;
			align-items: center;
			padding-right: 4px;
			color: @deepBlue;
		}
	}

	&__selected-structure:hover {
		background-color: @blue20;
	}

	&__structure {
		margin: 4px 0;

		&_name {
			display: inline-block;
			padding-right: 8px;
		}

		&_markers {
			display: inline-block;
		}
	}

	&__check-list {
		&__loading-panel {
			min-height: 100vh;
		}

		&__links-dialog {
			&-title {
				.d-semibold-16();

				margin-bottom: 22px;
			}
		}

		&__divider {
			margin: 0;
			border: none;
			border-bottom: 1px solid @black10;

			&_hr {
				margin: '0 16px';
			}
		}

		&__body-toggle {
			grid-area: toggle;
			margin: 10px 0;
			.d-regular-12();

			&-link .link-button__label {
				.d-regular-12();
			}

			&-link_active i {
				transform: rotate(180deg);
			}
		}

		&__float-actions {
			float: right;
		}

		&__indicators {
			display: flex;

			& > *:not(:last-child) {
				margin-right: 13px;
			}

			&:not(:empty) + &:not(:empty)::before {
				content: ' ';

				display: flex;
				align-self: center;

				width: 4px;
				height: 4px;
				margin: 14px;

				background: @grey30;
				border-radius: 50%;
			}

			&:first-child::before {
				display: none;
			}
		}

		&__status {
			position: relative;

			display: grid;
			grid-column-gap: 5px;
			grid-template-areas: 'select percent';
			grid-template-columns: auto 1fr;

			&-select {
				grid-area: select;
				color: @black95;

				&_hidden {
					visibility: hidden;
				}

				&_disabled {
					pointer-events: none;
				}

				&-menu {
					color: @black95;
				}
			}

			&-loader {
				position: absolute;
				width: 28px;
				height: 28px;
				margin: auto 0;
			}

			&-percent {
				display: flex;
				grid-area: percent;
				width: 60px;

				& input {
					height: 32px;
				}

				& .text-field__div {
					min-height: auto;
					margin: 0;
					padding-top: 7px;
					padding-bottom: 7px;
				}

				& .text-field__wrap {
					@media (max-width: @large-mobile-min) {
						background-color: @grey10;
						border: none;
						border-radius: 0;
					}
				}

				& button {
					padding: 5px 10px;
					color: @grey40;
					background-color: @grey10;
					border: none;

					&:active {
						color: @blue2;
					}

					&:first-of-type {
						transform: rotate(90deg);
						border-radius: 0 0 29px 29px;
					}

					&:last-of-type {
						transform: rotate(-90deg);
						border-radius: 0 0 29px 29px;
					}
				}
			}

			@media (max-width: @large-mobile-min) {
				grid-row-gap: 5px;
				grid-template-areas:
					'select'
					'percent';
				grid-template-rows: 1fr;
			}
		}

		&__m-nav {
			grid-area: nav;
		}

		&__m-list-detail-info &__m-list {
			.d-semibold-16();
		}

		&__m-item-detail .check-item-detail {
			grid-area: category;
		}

		&__m-item-detail {
			display: grid;
			grid-row-gap: 16px;
			grid-template-areas:
				'nav'
				'category';
			grid-template-rows: 1fr;
		}

		&__m-list-detail {
			display: grid;
			grid-row-gap: 16px;
			grid-template-areas:
				'nav'
				'info'
				'items';
			grid-template-rows: 1fr;

			&-info {
				grid-area: info;
				padding: 0 16px 16px;

				&-tags {
					display: flex;
					flex-wrap: wrap;
					margin-top: 16px;

					& > * {
						flex: 0 0 auto;
						margin: 2px;
					}
				}

				&-header {
					padding: 0;
				}

				&-menu {
					margin-left: auto;
				}
			}

			&-items {
				grid-area: items;
			}

			&-tabs {
				margin-bottom: 16px;
			}
		}

		&__m-list-detail-space-info {
			padding: 16px;

			&-header {
				margin-bottom: 0 !important;
			}

			&-link {
				margin-left: 20px;
				padding: 0;
			}
		}

		&__m-container {
			padding: 0;
		}

		&__m-check-list {
			&__container {
				padding: 16px 16px 0;
			}
		}

		&__m-lists {
			display: flex;
			flex-direction: column;
			padding: 0 16px;
		}

		&__m-list {
			display: flex;

			&-status {
				margin-top: 16px;
			}

			.d-semibold-14();

			&-bullet {
				flex: 0 0 auto;
			}

			&-text {
				margin-left: 10px;

				&-subtitle {
					margin-top: 4px;
					.d-regular-12();
				}
			}

			&-indicators {
				display: flex;
			}

			&-status {
				&-select {
					height: 32px;
					margin: 16px 0;
				}
			}
		}

		&__d-plate {
			margin-bottom: 16px;

			&-content {
				padding: 0 !important;
			}

			&_expanded &-header {
				box-shadow: 0 4px 4px rgb(0 0 0 / 5%);
			}

			&-header {
				cursor: pointer;

				display: grid;
				grid-gap: 0 12px;
				grid-template-areas:
					'diagram title'
					'diagram indicators'
					'diagram status'
					'diagram toggle';
				grid-template-columns: 50px auto;

				padding: 20px 24px 12px;

				&-title {
					display: inline-block;
					grid-area: title;

					.d-semibold-16();

					line-height: 21px;
					color: @black100;

					&-text {
						display: flex;
						align-items: center;
					}

					&-arrow {
						display: inline-block;
						margin: 0 22px;
						color: @blue2;
						vertical-align: middle;

						&_reversed {
							transform: rotate(180deg);
						}
					}
				}

				&-indicators {
					display: flex;
					grid-area: indicators;
					margin-bottom: 10px;
				}

				&-status {
					grid-area: status;

					&-wrap {
						float: left;
					}
				}

				&-diagram {
					grid-area: diagram;
				}
			}
		}

		&__d-grid {
			display: grid;
			grid-column-gap: 32px;
			grid-template-areas:
				'info-col main-col'
				'none main-col';
			grid-template-columns: 1fr 3.25fr;
		}

		&__d-info {
			grid-area: info-col;
		}

		&__d-actions {
			display: flex;
			justify-content: right;
			margin-bottom: 10px;

			& > button i {
				font-size: 20px !important;
			}
		}

		&__d-main {
			position: relative;

			display: grid;
			grid-area: main-col;
			grid-column-gap: 32px;
			grid-template-areas:
				'list-col detail-col'
				'list-col none';
			grid-template-columns: 3fr 2fr;

			&-list {
				grid-area: list-col;
			}

			&-detail {
				position: sticky;
				grid-area: detail-col;
			}
		}
	}

	&__comments-container {
		display: flex;
		width: 100%;
		gap: 32px;
		justify-content: space-between;
	}

	&__last-reply-mobile {
		margin-top: 12px;
	}

	@media (max-width: @tablet-max) {
		padding: 0;
	}
}
