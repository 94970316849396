@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.content {
	.d-semibold-14();
	color: @black75;
}

.subtitle {
	.d-regular-12();
	color: @black60;
}

.info {
	.d-regular-12();
	color: @deepBlue;
}
