@import (reference) '~@tehzor/ui-components/src/styles/colors';

.selectContainer {
	display: flex;
	column-gap: 15px;
}

.trigger {
	flex-grow: 1;
}

.iconButton {
	width: 48px;
	background-color: @buttonLightHover;
}
