@import (reference) '~@tehzor/ui-components/src/styles/colors.less';
@import (reference) '~@tehzor/ui-components/src/styles/variables.less';
@import (reference) '~@tehzor/ui-components/src/styles/typography.less';

.indicator {
	display: flex;
	align-items: center;
	white-space: pre;
	color: @grayBlue2;
	@media (max-width: @large-mobile-max) {
		padding: 0 16px;
	}
}
.title {
	.d-regular-14();
	@media (max-width: @large-mobile-max) {
		.d-regular-12();
	}
}
.loaderContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 40px;
	max-height: 40px;
	padding: 5px;
	margin-left: 10px;
}
.circleLoader {
	width: 19px;
	height: 19px;
}
